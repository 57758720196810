// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
// import { Textfit } from 'react-textfit'
import { StaticQuery, graphql } from 'gatsby'

import { GatsbyImage, getImage } from 'gatsby-plugin-image'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import TableOfContents from './toc'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const { Fragment } = React

// ----------------------------------------------------------------------------
// ---------------------------------------------------------------------- Query
// ----------------------------------------------------------------------------
export const query = graphql`
  query {
    img: file(relativePath: { eq: "cover.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** [description] */
const CoverPage = React.memo(({ lang }) => (
  <StaticQuery
    query={query}
    render={(data) => (
      <Fragment>
        <h1>Children of Change</h1>
        <h2>A Spiritual Pilgrimage</h2>
        <div className="cover as-paragraph">
          <GatsbyImage image={getImage(data.img)} objectFit="contain" />
        </div>
        <p>
          Surveying this panorama of life—from birth in the US Internment Camps
          through prejudices endured in childhood, leading to maturation of
          youthful idealism in Mississippi; from inner awakenings in Japan
          occasioned by the Buddhist spirit, then onwards to the yogic
          treasure-house of India with its air of miraculous improbability, and
          finally to Auroville…..” this is the story of Amrit`s spiritual
          journey from America to Japan and the Himalayan reaches of India, and
          finally to The Mother of Sri Aurobindo Ashram in Pondicherry, and
          Auroville.
        </p>
        <p>
          Connected with the international community of Auroville since its
          inception in 1968, Amrit follows its development and period of
          upheaval through the 1970`s and 1980`s, his relationship with The
          Mother, as well as with his teacher, Nilakantha Mahadeva Joshi, known
          as Panditji, of the Shakta tradition of Sri Vidya.
        </p>
        <TableOfContents lang={lang} />
      </Fragment>
    )}
  />
))

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default CoverPage
